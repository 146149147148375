import axios from "axios";
export default axios.create({
  baseURL: "https://6dejunioserver.tatusol.com/",
  headers: {
    "Content-type": "application/json"
  }
  /*headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  }*/
});
