class Token{
    constructor(){
        this.key="incnav";
    }
    save = (key,value)=>{
        localStorage.setItem(key, value);
    }
    get=(key)=>{
        return localStorage.getItem(key);
    }
    
    setData=(data)=>{
        localStorage.setItem(this.key, JSON.stringify(data));
    }
    getData=()=>{
        if(localStorage.getItem(this.key)){
            var data = JSON.parse(localStorage.getItem(this.key));
            if(data.registro){
                return data;
            }else{
                return null;
            }
        }else{
            return null;
        }

    }
    clear=()=>{
        localStorage.removeItem(this.key);
        localStorage.clear();
    }
}
export default new Token();