import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import { Message } from 'primereact/message';
import { Messages } from 'primereact/messages';
import servicio from '../servicio/RegistroServicio'
import token from '../componente/Token';
class Login extends Component{

    constructor(props){
        super(props);
        this.state={
            usuario:"",
            password:""
        }
        this.login=this.login.bind(this);
        //this.navigate= useNavigate();
    }

    login(){
        var data={
            usuario:this.state.usuario,
            password:this.state.password
        }
        servicio.login(data)
        .then((response) =>{   
            console.log(response);
            if(response.status===200&&response.data&&response.data.status===true){
                token.setData(response.data);
                window.location.href = "/";
            }else if(response.status===200){
                token.clear();
                this.msgs3.clear();
                this.msgs3.show({
                    severity: 'error', sumary:"Error", detail:response.data.error
                });
                this.setState({usuario:"",password:""});
            }else{
                token.clear();
                this.msgs3.clear();
                this.msgs3.show({
                    severity: 'error', sumary:"Error", detail:"Error en servidor"
                });
                this.setState({usuario:"",password:""});
            }
        }).catch( (err) =>{ 
            console.log(err);
            token.clear();
                this.msgs3.clear();
                this.msgs3.show({
                    severity: 'error', sumary:"Error", detail:"No existe conexion al servidor"
                });
                this.setState({usuario:"",password:""});
        });
    }
    render(){
        return(
            
            <div className="flex align-items-center justify-content-center">
                <br></br>
                <br></br>
                <div className="surface-card p-4 shadow-2 border-round w-full lg:w-3">
                    <div className="text-center mb-5">
                        {/*<img src="" alt="hyper" height={50} className="mb-3" />*/}
                        <div className="text-900 text-3xl font-medium mb-3">INGRESO SISTEMA</div>
                    </div>
                    <div >
                        <label htmlFor="usuario" className="block text-900 font-medium mb-2">Usuario</label>
                        <InputText id="usuario" type="text" className="w-full mb-3" value={this.state.usuario} onChange={(e) => this.setState({usuario:e.target.value})}/>
                        <label htmlFor="password" className="block text-900 font-medium mb-2">Contraseña</label>
                        <InputText id="password" type="password" className="w-full mb-3" value={this.state.password}  onChange={(e) => this.setState({password:e.target.value})}/>
                        <Button label="Ingresar" icon="pi pi-user" className="w-full p-button-success" onClick={() => this.login()}/>
                        <Messages ref={(el) => this.msgs3 = el} />
                        
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;