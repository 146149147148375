import React, { Component } from "react";
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import {Toast} from 'primereact/toast';
import servicio from '../servicio/RegistroServicio'
import Token from '../componente/Token'
class Registro extends Component{
    constructor(props){
        super(props);
        this.state={
            ci:0,
            encontrado:false,
            registro:null,        
            consulta:[],
            cantidad:0
        }
        this.ci="";
        this.bodyArea=this.bodyArea.bind(this);
        this.onBuscar=this.onBuscar.bind(this);
        this.onRegistrar=this.onRegistrar.bind(this);
        this.onChangeNumberValue=this.onChangeNumberValue.bind(this);
        this.getCurrentDate=this.getCurrentDate.bind(this);
        this.onUpdate=this.onUpdate.bind(this);
    }

    componentDidMount(){
        this.onUpdate();
    }
    bodyArea(rowData){
        if(rowData.area===2){
            return "URBANO/PROVINCIAL";
        }
        if(rowData.area===1){
            return "URBANO";
        }
        return "RURAL";
    }
    bodyNro(rowData,props){
        return props.rowIndex+1;
    }
    bodyDateOnly(rowData){
        var date=rowData.fechaEntrega.split("-");
        return date[2]+'/'+date[1]+'/'+date[0];
    }
    getCurrentDate(separator=''){

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    }
    getCurrentDateConsulta(separator=''){
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
    onUpdate(registro=null){
        if(Token.getData()!==null){

            var data={
                id:Token.getData().registro.id,
                fecha:this.getCurrentDateConsulta('-')                
            }
            servicio.getFecha(data).then(response =>{
                if(response.status===200&&response.data.status===true){
                    this.ci="";
                    this.setState({
                        consulta:response.data.registro,
                        cantidad:response.data.count,
                        registro:registro
                    });
                }else{
                    this.setState({consulta:[]});
                }
            });
        }else{
            this.ci="";
            this.setState({
                consulta:[],
                registro:registro,
                cantidad:0
            });
        }
    }
    onSalir(e){
        Token.clear();
        window.location.href = '/';
    }
    onChangeNumberValue(e){
        this.ci=e.target.value;
    }
    
    onRegistrar(e){
        if(Token.getData()!==null){
            var data={
                id:this.state.registro.id,
                token:Token.getData().registro.token,
                usuario:Token.getData().registro.login                
            }
            
            servicio.update(data).then(response =>{
                if(response.status===200&&response.data.status===true){
                    this.onUpdate();
                    this.toast.show({severity:'success', summary: 'Guardado Exitosamente', life: 1500});
                }else if(response.status===200&&response.data.status===false ){
                    this.toast.show({severity:'error', summary: response.data.error, life: 1500});
                    this.onSalir();
                }else{
                    this.toast.show({severity:'error', summary: response.data.error, life: 1500});
                    this.onSalir();
                }
            });
        }
    }
    onBuscar(e){
        if( this.ci===null ||this.ci==="" || this.ci===0){
            this.ci="";
            this.setState({                        
                registro: null,
            });
        }else{
            servicio.get(this.ci).then(response => {
                console.log(response);
                if(response.status===200){
                    this.setState({
                        registro: response.data.registro,
                    });
                    
                }else{
                    this.setState({                        
                        registro: null,
                    });
                }
            }).catch(e => {
                console.log(e);
                this.setState({                        
                    registro: null,
                });
            });
        }
    }
    
    render(){
        var footer=null;
        var mensaje=null;
        

        if(this.ci===0 ||this.ci===""||this.ci===null|| this.state.registro===null ||this.state.registro===null){
            if(this.ci===0||this.ci===""||this.ci===null){
                mensaje=null;
            }else{                
                mensaje=<Card  style={{border: '2px solid #ff0000'}}>                        
                            <Tag value={"NO SE ENCONTRADO A NINGUNA PERSONA CON EL CARNET " +this.ci} severity="danger" style={{marginRight: '.25em'}}/>
                        </Card>;
                
            }
        }else{           
            var data=[];
            data.push(this.state.registro); 
            if(this.state.registro.entregaExitosa===false){
                footer =<span>
                            <Button label=" REGISTRAR ENTREGA DE PRESENTE NAVIDEÑO " icon="pi pi-check" className="p-button-success" style={{marginRight: '.25em'}} onClick={this.onRegistrar}/>        
                        </span>;
                mensaje=<Card footer={footer} style={{border: '2px solid #3EC065'}}>
                        <DataTable value={data} responsiveLayout="stack" breakpoint="960px">
                            <Column field="carnet" header="Carnet" />
                            <Column field="nombre" header="Nombre" />
                            <Column field="distrito" header="Distrito" />
                            <Column field="unidadEducativa" header="Unidad Educativa" /> 
                            <Column body={this.bodyArea}  header="Area" /> 
                        </DataTable>
                    </Card>
            }else{
                footer =<span>
                        <Tag value={"YA RECIBIO SU PRESENTE NAVIDEÑO EN LA MESA:  "+this.state.registro.Usuario.login } severity="danger" style={{marginRight: '.25em'}}/>
                    </span>;
                mensaje=<Card footer={footer} style={{border: '2px solid #ff0000'}}>
                        <DataTable value={data} responsiveLayout="stack" breakpoint="960px">
                            <Column field="carnet" header="Carnet" />
                            <Column field="nombre" header="Nombre" />
                            <Column field="distrito" header="Distrito" />
                            <Column field="unidadEducativa" header="Unidad Educativa" /> 
                            <Column body={this.bodyArea} header="Area" /> 
                            <Column body={this.bodyDateOnly}/*field="fechaEntrega"*/ header="Entregado" />
                            <Column field="horaEntrega" header="Hora Entrega" /> 
                        </DataTable>
                    </Card>
            }
        }
        return (
            <Card>
                <h1>ENTREGA DE PRESENTA NAVIDEÑO</h1>
                <h1>DEL 9 al 19 DE DICIEMBRE - 2023</h1>
                <h1>{this.getCurrentDate('/')}</h1>
                {/*<h2>{"BIENVENIDO: "+ token.get("n") +" - "+token.get("d")}</h2>*/}
                <Fieldset legend="CI">
                
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-4">                            
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="p-inputgroup">
                                <InputNumber inputId="integeronly" value={this.ci} onValueChange={this.onChangeNumberValue} mode="decimal" useGrouping={false}  />
                                <Button icon="pi pi-search" className="p-button-warning" onClick={this.onBuscar}/>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">                            
                        </div>
                    </div>                
                </Fieldset>
                <Fieldset legend="RESPUESTA">
                    <Toast ref={(el) => this.toast = el} />
                    {mensaje}
                </Fieldset>
                            
                <Fieldset legend="REGISTRADOS">  
                    <p>TOTAL REGISTRADOS {this.state.cantidad}</p>                  
                    <Card >
                        <DataTable value={this.state.consulta} responsiveLayout="stack" breakpoint="960px">
                            <Column header="Nro" body={this.bodyNro}></Column>
                            <Column field="carnet" header="Carnet" />
                            <Column field="nombre" header="Nombre" />                     
                            <Column field="horaEntrega" header="Hora Entrega" /> 
                        </DataTable>
                    </Card>
                    
                </Fieldset>
            </Card>
            
        );
    }
}

export default Registro;