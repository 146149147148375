import http from "../servicio/Servicio"
class RegistroServicio{
    get= (data) => {
        return http.get("/api/registro/get/"+data,{token:"hola"});
    }
    update=data=>{
        return http.put("/api/registro/update", data);
    }
    login=data=>{
        return http.put("/api/registro/login", data);
    }
    getFecha=data=>{
        return http.post("/api/registro/fecha", data);
    }

    logout=data=>{
        return http.post("/api/registro/logout", data);
    }
    /*
    get15=data=> {
        return http.get("/planilla/get15/"+data);
    }
    login = data => {
        return http.post("/planilla/login", data);
    }
    registro(data) {
        return http.put("/planilla/registro", data);
    }    
    cerrar = data => {
        return http.post("/planilla/cerrar", data);
    }*/
}
export default new RegistroServicio();