import React, { Component } from "react";
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { InputNumber } from 'primereact/inputnumber';
import {Toast} from 'primereact/toast';
import servicio from '../servicio/RegistroServicio';
import Token from '../componente/Token';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

class Reporte extends Component{
    constructor(props){
        super(props);
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = (month === 0) ? 11 : month - 1;
        let prevYear = (prevMonth === 11) ? year - 1 : year;
        let nextMonth = (month === 11) ? 0 : month + 1;
        let nextYear = (nextMonth === 0) ? year + 1 : year;

        this.state = {
            date: null,    
            consulta:[]        
        };

        this.minDate = new Date();
        this.minDate.setMonth(prevMonth);
        this.minDate.setFullYear(prevYear);

        this.maxDate = new Date();
        this.maxDate.setMonth(nextMonth);
        this.maxDate.setFullYear(nextYear);

        this.invalidDates = [today];
        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Limpiar'
        });

        this.onBuscar=this.onBuscar.bind(this);
        
    }
    
    bodyNro(rowData,props){
        return props.rowIndex+1;
    }
    bodyDateOnly(rowData){
        var date=rowData.fechaEntrega.split("-");
        return date[2]+'/'+date[1]+'/'+date[0];
    }
    onBuscar(e){
        const separator='-';
        let newDate = this.state.date;
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const fecha=`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`;
        var data={
            id:Token.getData().registro.id,
            fecha:fecha             
        }
        servicio.getFecha(data).then(response =>{
            if(response.status===200){
                this.ci="";
                this.setState({
                    consulta:response.data.registro,
                });
            }else{
                this.setState({consulta:[]});
            }
        });
    }
    render(){
        return (
            <Card>
                <h1>REPORTE</h1>
                <h1>DICIEMBRE - 2022</h1>
                <Fieldset legend="SELECCIONAR FECHA">
                
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-4">                            
                        </div>
                        <div className="col-12 md:col-4">
                            <div className="p-inputgroup">                                
                                <Calendar id="basic" value={this.state.date} onChange={(e) => this.setState({ date: e.value })} dateFormat="dd/mm/yy" />                                
                                <Button icon="pi pi-search" className="p-button-warning" onClick={this.onBuscar}/>
                            </div>
                        </div>
                        <div className="col-12 md:col-4">                            
                        </div>
                    </div>                
                </Fieldset>
                <Fieldset legend="REGISTRADOS">  
                    <p>TOTAL REGISTRADOS {this.state.consulta.length}</p>                  
                    <Card >
                        <DataTable value={this.state.consulta} responsiveLayout="stack" breakpoint="960px">
                            <Column header="Nro" body={this.bodyNro}></Column>
                            <Column field="carnet" header="Carnet" />
                            <Column field="nombre" header="Nombre" />   
                            <Column body={this.bodyDateOnly}/*field="fechaEntrega"*/ header="Entregado" />                  
                            <Column field="horaEntrega" header="Hora Entrega" /> 
                        </DataTable>
                    </Card>
                    
                </Fieldset>
            </Card>
        );
    }
}
export default Reporte;